<template>
  <addField
    path="serve.php?f=testing&f2=reporting"
    :additionalAttributes="{'n_testgroup_id': row['n_testgroup_id'],'selected': this.row['n_id'] ,'updateTable':'false' ,function:'getInsertedInstantReporting'}"
  >
    <v-icon>mdi-file-pdf</v-icon>
  </addField>

  <!-- <a href="javascript:void(0);" onclick='AddField("", "", "serve.php?f=testing&f2=reporting",{ "n_testgroup_id": {$row["n_testgroup_id"]} ,"selected": {$row["n_id"]} ,"updateTable":"false" ,"function":"getInsertedInstantReporting"})'><i class="fas fa-file-pdf fs16" title="Create Group Report for {$row["n_testgroup_id"]}" style="font-size: 16px;"></i></a> -->
</template>

<script>
import addField from "@/commonComponents/addField.vue";
export default {
  props: ["row"],
  components: { addField },
};
</script>